import { defineStore } from 'pinia';
import { API, graphqlOperation } from 'aws-amplify';
import { Lender, Lead, Consumer } from '@/API';
import { deleteLead, deleteConsumer, deleteLender } from '@/graphql/mutations';
import { listLenders, listLeads, listConsumers } from '../graphql/queries';
import {
  createLender, createLead, createConsumer, setLeadActivationStatus,
} from '../graphql/callmutations';
import { getConsumerDetails, getLenderDetails } from '../graphql/callqueries';

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application

type MessageItem = {
  elementId: string,
  message: string
}

const useCallMaskerStore = defineStore('callmasker', {
  state: () => ({
    lenders: [] as Lender[],
    leads: [] as Lead[],
    consumers: [] as Consumer[],
    toastmessages: [] as MessageItem[],
  }),
  getters: {
    // doubleCount: (state) => state.count * 2,
    getLender: (state) => (id: string) => state.lenders.find((lender) => lender.id === id),
    getLead: (state) => (id: string) => state.leads.find((lead) => lead.id === id),
    getConsumer: (state) => (id: string) => {
      window.console.log(`getConsumer: ${id}`);
      return state.consumers.find((consumer) => consumer.id === id);
    },
  },
  actions: {
    async getLenders(refresh: boolean) {
      // console.log('called getLenders()');
      if (this.lenders.length === 0 || refresh) {
        const data: any = await API.graphql(graphqlOperation(listLenders));
        this.lenders = data.data.listLenders.items;
      }
    },
    async getLenderDetails(refresh: boolean, id: string) {
      console.log(`called getLenderDetails(${id})`);
      const data: any = await API.graphql(graphqlOperation(getLenderDetails(id)));
      // console.log(`getLender: ${JSON.stringify(data.data.getLender)}`);
      // this.consumers = data.data.listConsumers.items;
      if (data.data.getLender) {
        const index = this.lenders.findIndex((item) => item.id === id);

        if (index > -1) {
          console.log(`updating lender ${id}`);
          // only replace lead records or should I replace the whole node?
          this.lenders[index].leads = data.data.getLender.leads;
        } else {
          console.log(`lender NOT FOUND: ${id}`);
        }
      }
    },
    async createLender(lender: Lender) {
      // console.log(`called createLender(${JSON.stringify(lender)})`);
      const newLender: any = await API.graphql(graphqlOperation(createLender(lender)));
      // console.log(`newLender: (${JSON.stringify(newLender.data)})`);
      this.lenders.push(newLender.data.createLender);
      this.addToastMessage(`Lender ${lender.name} created`);
    },
    async getLeads(refresh: boolean) {
      // console.log('called getLenders()');
      if (this.leads.length === 0 || refresh) {
        const data: any = await API.graphql(graphqlOperation(listLeads));
        // console.log(`getLeads: ${JSON.stringify(data.data.listLeads.items)}`);
        this.leads = data.data.listLeads.items;
      }
    },
    async createLead(lead: Lead) {
      // console.log(`called createLead(${JSON.stringify(lead)})`);
      const newLead: any = await API.graphql(graphqlOperation(createLead(lead)));
      // console.log(`newLead: ${JSON.stringify(newLead.data)}`);
      const createLeadData = newLead.data.createLead;
      // console.log(`createLeadData: ${JSON.stringify(createLeadData)}`);
      this.leads.push(createLeadData);
      this.addToastMessage(`Lead: ${createLeadData?.consumer?.name} added to Lender: ${createLeadData?.lender?.name}`);
    },
    async getConsumers(refresh: boolean) {
      // console.log('called getLenders()');
      if (this.consumers.length === 0 || refresh) {
        const data: any = await API.graphql(graphqlOperation(listConsumers));
        this.consumers = data.data.listConsumers.items;
      }
    },
    async getConsumerDetails(refresh: boolean, id: string) {
      console.log(`called getConsumerDetails(${id})`);
      const data: any = await API.graphql(graphqlOperation(getConsumerDetails(id)));
      // console.log(`getConsumer: ${JSON.stringify(data.data.getConsumer)}`);
      // this.consumers = data.data.listConsumers.items;
      if (data.data.getConsumer) {
        // let vehicle = this.vehicles.find((item) => item.VIN === vin);
        const index = this.consumers.findIndex((item) => item.id === id);

        if (index > -1) {
          console.log(`updating consumer ${id}`);
          this.consumers[index] = data.data.getConsumer;
        } else {
          console.log(`consumer NOT FOUND: ${id}`);
        }
      }
    },
    async createConsumer(consumer: Consumer) {
      console.log(`called createConsumer(${JSON.stringify(consumer)})`);
      const newConsumer: any = await API.graphql(graphqlOperation(createConsumer(consumer)));
      // console.log(`newConsumer: (${JSON.stringify(newConsumer.data)})`);
      this.consumers.push(newConsumer.data.createConsumer);
      this.addToastMessage(`consumer ${consumer.name} created`);
    },
    async deleteLead(lead: Lead) {
      // console.log(`called deleteLead(${JSON.stringify(lead)})`);
      const response: any = await API.graphql(graphqlOperation(
        deleteLead,
        {
          input: {
            id: lead.id,
          },
        },
      ));
      console.log(`response: (${JSON.stringify(response.data)})`);
      this.addToastMessage(`lead ${lead?.id} deleted`);
      this.getLeads(true);
    },
    async deleteConsumer(consumer: Consumer) {
      // console.log(`called deleteConsumer(${JSON.stringify(consumer)})`);
      const response: any = await API.graphql(graphqlOperation(
        deleteConsumer,
        {
          input: {
            id: consumer.id,
          },
        },
      ));
      // console.log(`response: (${JSON.stringify(response.data)})`);
      this.addToastMessage(`consumer ${consumer.name} deleted`);
      this.getConsumers(true);
    },
    async deleteLender(lender: Lender) {
      // console.log(`called deleteLender(${JSON.stringify(lender)})`);
      const response: any = await API.graphql(graphqlOperation(
        deleteLender,
        {
          input: {
            id: lender.id,
          },
        },
      ));
      // console.log(`response: (${JSON.stringify(response.data)})`);
      this.addToastMessage(`lender ${lender.name} deleted`);
      this.getLenders(true);
    },
    async removeToastMessage(elementId: string) {
      this.toastmessages = this.toastmessages.filter((item) => item.elementId !== elementId);
    },
    async addToastMessage(message: string) {
      const randomValue = Math.floor(Math.random() * 100000);
      const elementId = `liveToast${randomValue}`;
      this.toastmessages.push({ elementId, message });
    },
    async setLeadActivationStatus(leadId: string, status: boolean) {
      console.log(`called setLeadActivationStatus(${leadId}, ${status.toString()})`);
      const response: any = await API.graphql(
        graphqlOperation(setLeadActivationStatus(leadId, status)),
      );
      // console.log(`response: (${JSON.stringify(response.data)})`);
      this.addToastMessage(`lead ${leadId} status set to ${status.toString()}`);
      this.getLeads(true);
    },
  },
});

export default useCallMaskerStore;
