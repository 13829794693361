import { Amplify, Auth } from 'aws-amplify';
import { createApp } from 'vue';
import AmplifyVue from '@aws-amplify/ui-vue';
import { createPinia } from 'pinia';
import awsExports from './aws-exports';
import App from './App.vue';
import router from './router';

const pinia = createPinia();

awsExports.oauth.redirectSignIn = `${window.location.origin}/`;
awsExports.oauth.redirectSignOut = `${window.location.origin}/`;

Amplify.configure(awsExports);
Auth.configure(awsExports);

createApp(App)
  .use(pinia)
  .use(router)
  .use(AmplifyVue)
  .mount('#app');
