// import { Lender, Consumer, Lead } from '@/API';

// const timestamp = (addDays: number): number => Math.floor(
//   new Date().setDate(new Date().getDate() + addDays)
//   / 1000,
// );

// const timestampNumber = (date: Date): number => Math.floor(new Date(date).getTime()/1000);
const listLeads = (): string => {
  // const now = Math.floor(new Date().getTime() / 1000);
  const query = `
    query MyQuery {
      listLeads {
        items {
          consumer {
            amount
            id
            createdAt
            income
            name
            phone
            purpose
            score
            updatedAt
          }
          active
          lender {
            account
            id
            name
            phone
            validated
            updatedAt
          }
        }
      }
    }
  `;
  // console.log(mutation);
  return query;
};

const getConsumerDetails = (id: string): string => {
  // const now = Math.floor(new Date().getTime() / 1000);
  const query = `
    query MyQuery {
      getConsumer(id: "${id}") {
        active
        amount
        id
        createdAt
        income
        name
        phone
        purpose
        score
        updatedAt
        leads {
          items {
            consumerLeadsId
            createdAt
            id
            lenderLeadsId
            lender {
              account
              createdAt
              id
              name
              phone
              updatedAt
              validated
            }
            active
            updatedAt
          }
        }
      }
    }
  `;
  // console.log(mutation);
  return query;
};

const getLenderDetails = (id: string): string => {
  // const now = Math.floor(new Date().getTime() / 1000);
  const query = `
    query MyQuery {
      getLender(id: "${id}") {
        account
        createdAt
        id
        leads {
          items {
            id
            active
            consumer {
              active
              amount
              createdAt
              id
              income
              name
              phone
              purpose
              score
              updatedAt
            }
          }
        }
        name
        phone
        updatedAt
        validated
      }
    }
  `;
  // console.log(mutation);
  return query;
};

export { listLeads, getConsumerDetails, getLenderDetails };
