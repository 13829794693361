/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getLender = /* GraphQL */ `
  query GetLender($id: ID!) {
    getLender(id: $id) {
      id
      name
      phone
      leads {
        items {
          id
          active
          added
          edited
          createdAt
          updatedAt
          lenderLeadsId
          consumerLeadsId
        }
        nextToken
      }
      callLogs {
        items {
          id
          lenderId
          connected
          incomingNumber
          outgoingNumber
          choices
          type
          added
          edited
          createdAt
          updatedAt
          lenderCallLogsId
        }
        nextToken
      }
      validated
      account
      added
      edited
      createdAt
      updatedAt
    }
  }
`;
export const listLenders = /* GraphQL */ `
  query ListLenders(
    $filter: ModelLenderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLenders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        phone
        leads {
          nextToken
        }
        callLogs {
          nextToken
        }
        validated
        account
        added
        edited
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLead = /* GraphQL */ `
  query GetLead($id: ID!) {
    getLead(id: $id) {
      id
      lender {
        id
        name
        phone
        leads {
          nextToken
        }
        callLogs {
          nextToken
        }
        validated
        account
        added
        edited
        createdAt
        updatedAt
      }
      active
      consumer {
        id
        leads {
          nextToken
        }
        phone
        active
        name
        score
        income
        amount
        purpose
        added
        edited
        createdAt
        updatedAt
      }
      added
      edited
      createdAt
      updatedAt
      lenderLeadsId
      consumerLeadsId
    }
  }
`;
export const listLeads = /* GraphQL */ `
  query ListLeads(
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lender {
          id
          name
          phone
          validated
          account
          added
          edited
          createdAt
          updatedAt
        }
        active
        consumer {
          id
          phone
          active
          name
          score
          income
          amount
          purpose
          added
          edited
          createdAt
          updatedAt
        }
        added
        edited
        createdAt
        updatedAt
        lenderLeadsId
        consumerLeadsId
      }
      nextToken
    }
  }
`;
export const getCallLog = /* GraphQL */ `
  query GetCallLog($id: ID!) {
    getCallLog(id: $id) {
      id
      lender {
        id
        name
        phone
        leads {
          nextToken
        }
        callLogs {
          nextToken
        }
        validated
        account
        added
        edited
        createdAt
        updatedAt
      }
      lenderId
      connected
      consumer {
        id
        leads {
          nextToken
        }
        phone
        active
        name
        score
        income
        amount
        purpose
        added
        edited
        createdAt
        updatedAt
      }
      incomingNumber
      outgoingNumber
      choices
      type
      added
      edited
      createdAt
      updatedAt
      lenderCallLogsId
    }
  }
`;
export const listCallLogs = /* GraphQL */ `
  query ListCallLogs(
    $filter: ModelCallLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCallLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lender {
          id
          name
          phone
          validated
          account
          added
          edited
          createdAt
          updatedAt
        }
        lenderId
        connected
        consumer {
          id
          phone
          active
          name
          score
          income
          amount
          purpose
          added
          edited
          createdAt
          updatedAt
        }
        incomingNumber
        outgoingNumber
        choices
        type
        added
        edited
        createdAt
        updatedAt
        lenderCallLogsId
      }
      nextToken
    }
  }
`;
export const getConsumer = /* GraphQL */ `
  query GetConsumer($id: ID!) {
    getConsumer(id: $id) {
      id
      leads {
        items {
          id
          active
          added
          edited
          createdAt
          updatedAt
          lenderLeadsId
          consumerLeadsId
        }
        nextToken
      }
      phone
      active
      name
      score
      income
      amount
      purpose
      added
      edited
      createdAt
      updatedAt
    }
  }
`;
export const listConsumers = /* GraphQL */ `
  query ListConsumers(
    $filter: ModelConsumerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsumers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        leads {
          nextToken
        }
        phone
        active
        name
        score
        income
        amount
        purpose
        added
        edited
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLenderByPhone = /* GraphQL */ `
  query GetLenderByPhone(
    $phone: String!
    $sortDirection: ModelSortDirection
    $filter: ModelLenderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLenderByPhone(
      phone: $phone
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        phone
        leads {
          nextToken
        }
        callLogs {
          nextToken
        }
        validated
        account
        added
        edited
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCallLogsByLenderId = /* GraphQL */ `
  query GetCallLogsByLenderId(
    $lenderId: String!
    $added: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCallLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCallLogsByLenderId(
      lenderId: $lenderId
      added: $added
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lender {
          id
          name
          phone
          validated
          account
          added
          edited
          createdAt
          updatedAt
        }
        lenderId
        connected
        consumer {
          id
          phone
          active
          name
          score
          income
          amount
          purpose
          added
          edited
          createdAt
          updatedAt
        }
        incomingNumber
        outgoingNumber
        choices
        type
        added
        edited
        createdAt
        updatedAt
        lenderCallLogsId
      }
      nextToken
    }
  }
`;
export const getTypedCallLogsByAdded = /* GraphQL */ `
  query GetTypedCallLogsByAdded(
    $type: String!
    $added: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCallLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTypedCallLogsByAdded(
      type: $type
      added: $added
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lender {
          id
          name
          phone
          validated
          account
          added
          edited
          createdAt
          updatedAt
        }
        lenderId
        connected
        consumer {
          id
          phone
          active
          name
          score
          income
          amount
          purpose
          added
          edited
          createdAt
          updatedAt
        }
        incomingNumber
        outgoingNumber
        choices
        type
        added
        edited
        createdAt
        updatedAt
        lenderCallLogsId
      }
      nextToken
    }
  }
`;
export const getConsumerByLoanPurpose = /* GraphQL */ `
  query GetConsumerByLoanPurpose(
    $purpose: String!
    $sortDirection: ModelSortDirection
    $filter: ModelConsumerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getConsumerByLoanPurpose(
      purpose: $purpose
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        leads {
          nextToken
        }
        phone
        active
        name
        score
        income
        amount
        purpose
        added
        edited
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
