import { Lender, Consumer, Lead } from '@/API';

// const timestamp = (addDays: number): number => Math.floor(
//   new Date().setDate(new Date().getDate() + addDays)
//   / 1000,
// );

// const timestampNumber = (date: Date): number => Math.floor(new Date(date).getTime()/1000);

const createLender = (lender: Lender): string => {
  const now = Math.floor(new Date().getTime() / 1000);
  const mutation = `
    mutation MyMutation {
        createLender(
            input: {
                account: "${lender.account}", 
                name: "${lender.name}", 
                phone: "${lender.phone}",
                validated: ${lender.validated},
                added: ${now},
                edited: ${now},
            }
        ) {
          account
          createdAt
          id
          name
          phone
          updatedAt
          validated
        }
      }
    `;
    // console.log(mutation);
  return mutation;
};

const createConsumer = (consumer: Consumer): string => {
  console.log(`createConsumer in callmutations.ts: ${JSON.stringify(consumer)}`);
  const now = Math.floor(new Date().getTime() / 1000);
  const mutation = `
   mutation MyMutation {
    createConsumer(input: {
        active: ${consumer.active},
        income: ${consumer.income}, 
        name: "${consumer.name}", 
        phone: "${consumer.phone}",
        score: ${consumer.score},
        amount: ${consumer.amount},
        purpose: "${consumer.purpose}",
        added: ${now},
        edited: ${now},
    }) {
      active
      createdAt
      id
      income
      name
      phone
      score
      amount
      purpose
      updatedAt
    }
  }
  `;
  console.log(mutation);
  return mutation;
};

const createLead = (lead: Lead): string => {
  const now = Math.floor(new Date().getTime() / 1000);
  const mutation = `
    mutation MyMutation {
        createLead(input: {
            active: ${lead.active},
            lenderLeadsId: "${lead.lenderLeadsId}",
            consumerLeadsId: "${lead.consumerLeadsId}",
            added: ${now},
            edited: ${now},
        }) {
            active
            consumer {
                id
                name
                phone
                score
                income
                amount
                purpose
            }
            createdAt
            id
            lender {
                id
                name
                phone
                validated
            }
            updatedAt
        }
    }
  `;
  // console.log(mutation);
  return mutation;
};

const setLeadActivationStatus = (leadId: string, status: boolean): string => {
  const now = Math.floor(new Date().getTime() / 1000);
  const mutation = `
    mutation MyMutation {
      updateLead(input: {
        id: "${leadId}", 
        active: ${status},
        edited: ${now},
      }) {
        active
        added
        edited
        id
        updatedAt
      }
    }
  `;
  console.log(mutation);
  return mutation;
};

export {
  createLender,
  createConsumer,
  createLead,
  setLeadActivationStatus,
};
