/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createLender = /* GraphQL */ `
  mutation CreateLender(
    $input: CreateLenderInput!
    $condition: ModelLenderConditionInput
  ) {
    createLender(input: $input, condition: $condition) {
      id
      name
      phone
      leads {
        items {
          id
          active
          added
          edited
          createdAt
          updatedAt
          lenderLeadsId
          consumerLeadsId
        }
        nextToken
      }
      callLogs {
        items {
          id
          lenderId
          connected
          incomingNumber
          outgoingNumber
          choices
          type
          added
          edited
          createdAt
          updatedAt
          lenderCallLogsId
        }
        nextToken
      }
      validated
      account
      added
      edited
      createdAt
      updatedAt
    }
  }
`;
export const updateLender = /* GraphQL */ `
  mutation UpdateLender(
    $input: UpdateLenderInput!
    $condition: ModelLenderConditionInput
  ) {
    updateLender(input: $input, condition: $condition) {
      id
      name
      phone
      leads {
        items {
          id
          active
          added
          edited
          createdAt
          updatedAt
          lenderLeadsId
          consumerLeadsId
        }
        nextToken
      }
      callLogs {
        items {
          id
          lenderId
          connected
          incomingNumber
          outgoingNumber
          choices
          type
          added
          edited
          createdAt
          updatedAt
          lenderCallLogsId
        }
        nextToken
      }
      validated
      account
      added
      edited
      createdAt
      updatedAt
    }
  }
`;
export const deleteLender = /* GraphQL */ `
  mutation DeleteLender(
    $input: DeleteLenderInput!
    $condition: ModelLenderConditionInput
  ) {
    deleteLender(input: $input, condition: $condition) {
      id
      name
      phone
      leads {
        items {
          id
          active
          added
          edited
          createdAt
          updatedAt
          lenderLeadsId
          consumerLeadsId
        }
        nextToken
      }
      callLogs {
        items {
          id
          lenderId
          connected
          incomingNumber
          outgoingNumber
          choices
          type
          added
          edited
          createdAt
          updatedAt
          lenderCallLogsId
        }
        nextToken
      }
      validated
      account
      added
      edited
      createdAt
      updatedAt
    }
  }
`;
export const createLead = /* GraphQL */ `
  mutation CreateLead(
    $input: CreateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    createLead(input: $input, condition: $condition) {
      id
      lender {
        id
        name
        phone
        leads {
          nextToken
        }
        callLogs {
          nextToken
        }
        validated
        account
        added
        edited
        createdAt
        updatedAt
      }
      active
      consumer {
        id
        leads {
          nextToken
        }
        phone
        active
        name
        score
        income
        amount
        purpose
        added
        edited
        createdAt
        updatedAt
      }
      added
      edited
      createdAt
      updatedAt
      lenderLeadsId
      consumerLeadsId
    }
  }
`;
export const updateLead = /* GraphQL */ `
  mutation UpdateLead(
    $input: UpdateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    updateLead(input: $input, condition: $condition) {
      id
      lender {
        id
        name
        phone
        leads {
          nextToken
        }
        callLogs {
          nextToken
        }
        validated
        account
        added
        edited
        createdAt
        updatedAt
      }
      active
      consumer {
        id
        leads {
          nextToken
        }
        phone
        active
        name
        score
        income
        amount
        purpose
        added
        edited
        createdAt
        updatedAt
      }
      added
      edited
      createdAt
      updatedAt
      lenderLeadsId
      consumerLeadsId
    }
  }
`;
export const deleteLead = /* GraphQL */ `
  mutation DeleteLead(
    $input: DeleteLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    deleteLead(input: $input, condition: $condition) {
      id
      lender {
        id
        name
        phone
        leads {
          nextToken
        }
        callLogs {
          nextToken
        }
        validated
        account
        added
        edited
        createdAt
        updatedAt
      }
      active
      consumer {
        id
        leads {
          nextToken
        }
        phone
        active
        name
        score
        income
        amount
        purpose
        added
        edited
        createdAt
        updatedAt
      }
      added
      edited
      createdAt
      updatedAt
      lenderLeadsId
      consumerLeadsId
    }
  }
`;
export const createCallLog = /* GraphQL */ `
  mutation CreateCallLog(
    $input: CreateCallLogInput!
    $condition: ModelCallLogConditionInput
  ) {
    createCallLog(input: $input, condition: $condition) {
      id
      lender {
        id
        name
        phone
        leads {
          nextToken
        }
        callLogs {
          nextToken
        }
        validated
        account
        added
        edited
        createdAt
        updatedAt
      }
      lenderId
      connected
      consumer {
        id
        leads {
          nextToken
        }
        phone
        active
        name
        score
        income
        amount
        purpose
        added
        edited
        createdAt
        updatedAt
      }
      incomingNumber
      outgoingNumber
      choices
      type
      added
      edited
      createdAt
      updatedAt
      lenderCallLogsId
    }
  }
`;
export const updateCallLog = /* GraphQL */ `
  mutation UpdateCallLog(
    $input: UpdateCallLogInput!
    $condition: ModelCallLogConditionInput
  ) {
    updateCallLog(input: $input, condition: $condition) {
      id
      lender {
        id
        name
        phone
        leads {
          nextToken
        }
        callLogs {
          nextToken
        }
        validated
        account
        added
        edited
        createdAt
        updatedAt
      }
      lenderId
      connected
      consumer {
        id
        leads {
          nextToken
        }
        phone
        active
        name
        score
        income
        amount
        purpose
        added
        edited
        createdAt
        updatedAt
      }
      incomingNumber
      outgoingNumber
      choices
      type
      added
      edited
      createdAt
      updatedAt
      lenderCallLogsId
    }
  }
`;
export const deleteCallLog = /* GraphQL */ `
  mutation DeleteCallLog(
    $input: DeleteCallLogInput!
    $condition: ModelCallLogConditionInput
  ) {
    deleteCallLog(input: $input, condition: $condition) {
      id
      lender {
        id
        name
        phone
        leads {
          nextToken
        }
        callLogs {
          nextToken
        }
        validated
        account
        added
        edited
        createdAt
        updatedAt
      }
      lenderId
      connected
      consumer {
        id
        leads {
          nextToken
        }
        phone
        active
        name
        score
        income
        amount
        purpose
        added
        edited
        createdAt
        updatedAt
      }
      incomingNumber
      outgoingNumber
      choices
      type
      added
      edited
      createdAt
      updatedAt
      lenderCallLogsId
    }
  }
`;
export const createConsumer = /* GraphQL */ `
  mutation CreateConsumer(
    $input: CreateConsumerInput!
    $condition: ModelConsumerConditionInput
  ) {
    createConsumer(input: $input, condition: $condition) {
      id
      leads {
        items {
          id
          active
          added
          edited
          createdAt
          updatedAt
          lenderLeadsId
          consumerLeadsId
        }
        nextToken
      }
      phone
      active
      name
      score
      income
      amount
      purpose
      added
      edited
      createdAt
      updatedAt
    }
  }
`;
export const updateConsumer = /* GraphQL */ `
  mutation UpdateConsumer(
    $input: UpdateConsumerInput!
    $condition: ModelConsumerConditionInput
  ) {
    updateConsumer(input: $input, condition: $condition) {
      id
      leads {
        items {
          id
          active
          added
          edited
          createdAt
          updatedAt
          lenderLeadsId
          consumerLeadsId
        }
        nextToken
      }
      phone
      active
      name
      score
      income
      amount
      purpose
      added
      edited
      createdAt
      updatedAt
    }
  }
`;
export const deleteConsumer = /* GraphQL */ `
  mutation DeleteConsumer(
    $input: DeleteConsumerInput!
    $condition: ModelConsumerConditionInput
  ) {
    deleteConsumer(input: $input, condition: $condition) {
      id
      leads {
        items {
          id
          active
          added
          edited
          createdAt
          updatedAt
          lenderLeadsId
          consumerLeadsId
        }
        nextToken
      }
      phone
      active
      name
      score
      income
      amount
      purpose
      added
      edited
      createdAt
      updatedAt
    }
  }
`;
